
import {
  defineComponent
} from 'vue'
import TmDropdownDownload from '@/components/shared/dropdowns/TmDropdownDownload.vue'
import TmAutoSizePanel from '@/components/shared/autoSize/TmAutoSizePanel.vue'
import TmAutoSizePanelItemButton from '@/components/shared/autoSize/panel/TmAutoSizePanelItemButton.vue'

export default defineComponent({
  components: {
    TmDropdownDownload,
    TmAutoSizePanel,
    TmAutoSizePanelItemButton,
  },
})
