
import type { PropType } from 'vue'
import {
  defineComponent, ref, toRefs
} from 'vue'
import DetailsTable from '@/components/shared/templates/details/DetailsTable.vue'
import TmStatus from '@/components/shared/TmStatus.vue'
import type { DetailsTableItemType } from '@/definitions/shared/types'
import type { SmsSurveyLine } from '@/definitions/services/smsSurvey/types'

export default defineComponent({
  components: {
    DetailsTable,
    TmStatus,
  },
  props: {
    survey: {
      type: Object as PropType<SmsSurveyLine>,
      required: true,
    },
  },
  setup(props) {
    const { survey } = toRefs(props)

    const tableValue = ref<DetailsTableItemType[]>([
      {
        label: 'Survey name',
        value: survey.value.name,
      },
      {
        label: 'Date created',
        value: '17 Jun 2022 7:39 am',
      },
      {
        label: 'Sent from',
        value: '+447441447724 (US Support)',
      },
      {
        label: 'Recipients',
        value: '',
      },
      {
        label: 'Question 1',
        value: '',
      },
      {
        label: 'Question 2',
        value: '',
      },
      {
        label: 'Question 3',
        value: '',
      },
      {
        label: 'Question 4',
        value: '',
      },
      {
        label: 'Status',
        value: '',
      },
      {
        label: 'Last response',
        value: '17 Jun 2022 7:39 am',
      },
    ])

    return {
      tableValue,
    }
  },
})
