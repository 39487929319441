import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_tm_auto_size_panel_item_button = _resolveComponent("tm-auto-size-panel-item-button")!
  const _component_tm_dropdown_download = _resolveComponent("tm-dropdown-download")!
  const _component_tm_auto_size_panel = _resolveComponent("tm-auto-size-panel")!

  return (_openBlock(), _createBlock(_component_tm_auto_size_panel, {
    "max-visible-items": 4,
    "min-hidden-items": 2
  }, {
    default: _withCtx(() => [
      _createVNode(_component_tm_auto_size_panel_item_button, {
        text: "View survey",
        icon: "call_split",
        "icon-rotate": 180
      }),
      _createVNode(_component_tm_auto_size_panel_item_button, {
        text: "Duplicate survey",
        icon: "tmi-duplicate"
      }),
      _createVNode(_component_tm_auto_size_panel_item_button, {
        text: "Cancel survey",
        icon: "cancel"
      }),
      _createVNode(_component_tm_dropdown_download, { label: "Download results" })
    ]),
    _: 1
  }))
}