import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_sms_survey_details_overview_buttons = _resolveComponent("sms-survey-details-overview-buttons")!
  const _component_sms_survey_details_overview_table = _resolveComponent("sms-survey-details-overview-table")!

  return (_openBlock(), _createElementBlock("div", null, [
    _createVNode(_component_sms_survey_details_overview_buttons),
    _createVNode(_component_sms_survey_details_overview_table, {
      survey: _ctx.survey,
      class: "mt-4"
    }, null, 8, ["survey"])
  ]))
}