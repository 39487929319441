
import type { PropType } from 'vue'
import {
  defineComponent
} from 'vue'
import SmsSurveyDetailsOverviewButtons from '@/components/pages/services/smsSurvey/details/overview/SmsSurveyDetailsOverviewButtons.vue'
import SmsSurveyDetailsOverviewTable from '@/components/pages/services/smsSurvey/details/overview/SmsSurveyDetailsOverviewTable.vue'
import type { SmsSurveyLine } from '@/definitions/services/smsSurvey/types'

export default defineComponent({
  components: {
    SmsSurveyDetailsOverviewButtons,
    SmsSurveyDetailsOverviewTable,
  },
  props: {
    survey: {
      type: Object as PropType<SmsSurveyLine>,
      required: true,
    },
  },
})
